import { IError } from '@/types/common';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { axiosRequestConfiguration } from './config';

const axiosInstance = axios.create(axiosRequestConfiguration);
axiosInstance.interceptors.request.use(async (existedConfig) => {
  const token = await localStorage.getItem('loginToken');
  if (!!token) {
    existedConfig.headers.Authorization = `Bearer ${token}`;
  }
  return existedConfig;
});

axiosInstance.interceptors.response.use(
  (success: AxiosResponse) => {
    // const accessToken = res?.data?.accessToken;

    // if (accessToken) {
    //   res.headers.authorization = accessToken;
    //   localStorage.setItem('access_token', accessToken);
    // }
    // const refreshToken = res?.data?.refreshToken;
    // if (refreshToken) {
    //   localStorage.setItem('refresh_token', refreshToken);
    // }
    return Promise.resolve(success);
  },
  async (error: AxiosError) => {
    const status = error.response?.status;
    const data = error.response?.data as IError;
    // 로그인 API 에러인 경우
    if (data.requestURI === '/sign-in') {
      return Promise.reject(error);
    }
    // 그 외 프렌차이즈 API 요청인 경우
    switch (status) {
      case 400:
        if (data.code === 'G0002') {
          alert('요청에 유효하지 않은 값이 존재합니다.');
        } else if (data.code === 'R0007') {
          alert('환급정보가 존재하지 않습니다.');
        } else {
          alert(
            '알 수 없는 에러가 발생했습니다. 고객센터에 문의해주세요.\nT. 02-6275-8011',
          );
        }
        break;
      case 401: {
        if (data.code === 'A0001') {
          alert('인증 정보가 존재하지 않습니다. 다시 로그인 해주세요.');
        } else if (data.code === 'A0002') {
          alert('인증 정보가 만료되었습니다. 다시 로그인 해주세요.');
        } else {
          alert('인증 정보가 유효하지 않습니다. 다시 로그인 해주세요.');
        }
        localStorage.clear();
        window.location.href = '/';
        break;
      }
      case 403: {
        alert('권한이 없습니다.');
        break;
      }
      case 404: {
        alert('찾을 수 없는 페이지입니다.');
        localStorage.clear();
        window.location.href = '/';
        break;
      }
      case 500: {
        alert(
          '일시적인 서버 오류가 발생했습니다. 고객센터에 문의해주세요.\nT. 02-6275-8011',
        );
        break;
      }
      default: {
        alert(
          '알 수 없는 에러가 발생했습니다. 고객센터에 문의해주세요.\nT. 02-6275-8011',
        );
      }
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
