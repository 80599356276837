import { getRefundStatement } from '@/api/refund';
import { ISignInResponse } from '@/types/login';
import { RefundStatementResponse } from '@/types/refundStatement';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useAppSelector } from './useReduxHooks';

const useStatement = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const { userInfo } = useAppSelector((state) => state.user);
  const { franchiseeIndex } = userInfo as ISignInResponse;
  const [shouldRefetch, setShouldRefetch] = useState(true);

  const { data, isSuccess } = useQuery<RefundStatementResponse, null>(
    ['refundStatement'],
    async () => await getRefundStatement(franchiseeIndex),
    {
      onSuccess: (res) => {
        setTotalPage(res.totalPages);
        setTotalElements(res.totalElements);
        setShouldRefetch(true);
      },
      onError: () => {
        setShouldRefetch(false);
      },
      retry: false,
      refetchOnWindowFocus: shouldRefetch,
    },
  );

  return {
    data,
    isSuccess,
    totalPage,
    totalElements,
    pageNumber,
    setPageNumber,
  };
};

export default useStatement;
