import React from 'react';
import styled from 'styled-components';

import LoginPage from '@/components/login/LoginPage';
import Footer from '@/components/common/Footer';

function Login() {
  return (
    <Wrapper>
      <LoginPage />
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: calc(100vh);
  background-color: ${(props) => props.theme.blueColors.scale3};
`;

export default Login;
