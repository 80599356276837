import { AxiosRequestConfig } from 'axios';

export const SERVER_PROD = 'https://admin.ktaxpay.com';
export const SERVER_TEST = 'http://123.142.246.2:50921'; //테스트
export const SERVER_PROD_TEST = 'http://3.35.8.143:50921'; //운영 테스트

const isProduction = process.env.NODE_ENV === 'production';

export const axiosRequestConfiguration: AxiosRequestConfig = {
	baseURL: isProduction ? SERVER_PROD : SERVER_TEST,
	responseType: 'json',
	headers: { 'Content-Type': 'application/json' },
};
