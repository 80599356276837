import React, { Dispatch, SetStateAction, useState } from 'react';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import logoImage from '@/assets/common/logo.png';
import ErrorMessage from '@/components/common/ErrorMessage';
import FlexWrap from '@/components/common/FlexWrap';
import { login } from '@/api/user';
import { ISignInPayload, ISignInResponse } from '@/types/login';
import { useAppDispatch } from '@/hooks/useReduxHooks';
import { loginState, userState } from '@/store/modules/user';
import { getSubCompanySerial } from '@/util/common';

type onSubmitProps = {
  businessNumber: string;
  password: string;
};

function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      businessNumber: '',
      password: '',
    },
  });

  const loginMutation = useMutation<
    ISignInResponse,
    AxiosError<any>,
    ISignInPayload
  >((payload) => login(payload), {
    retry: false,
    onSuccess: (res) => {
      localStorage.setItem('name', res.storeName);
      localStorage.setItem('loginToken', res.accessToken);
      localStorage.setItem('taxFreeStoreNumber', res.taxFreeStoreNumber);
      dispatch(userState(res));
      dispatch(loginState(true));
      if (res.globalPayOnly) {
        navigate('/global-payment');
      } else {
        navigate('/');
      }
    },
    onError: (error) => {
      const errorCode = error.response?.data.code;
      if (errorCode === 'G0002') {
        setError('businessNumber', {
          message: '등록되지 않은 사업자번호 입니다.',
        });
      }
      if (errorCode === 'G0001') {
        setError('password', {
          message:
            '비밀번호가 틀렸습니다.\n(APP 대표자 계정 비밀번호와 동일하며, APP 내 비밀번호찾기 통해 확인가능합니다. )',
        });
      }
    },
  });

  const onSubmit = async ({ businessNumber, password }: onSubmitProps) => {
    const payload = {
      businessNumber: businessNumber.slice(0, 10),
      subCompanySerial: getSubCompanySerial(businessNumber),
      password,
      userSelector: 'FRANCHISEE',
      forcedCheck: true,
      isWeb: true,
      pushToken: '',
    };
    loginMutation.mutate(payload);
  };

  const [isNumberOnly, setIsNumberOnly] = useState('');

  return (
    <>
      <LoginContainer>
        <Container>
          <Header>
            <Logo src={logoImage} />
            <Title>KTP 환급내역 서비스</Title>
          </Header>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <InputField>
              <Label>사업자등록번호 또는 법인사업자번호</Label>
              <Input
                {...register('businessNumber', {
                  required: '아이디를 입력하세요.',
                })}
                maxLength={14}
                type='text'
                placeholder='"-" 를 제외한 숫자만 입력해 주세요.'
                onInput={(e) => {
                  const input = e.target as HTMLInputElement;
                  const reg = /[^0-9]/g;

                  if (reg.test(input.value)) {
                    setIsNumberOnly(
                      '유효하지 않은 입력입니다. 숫자만 입력해 주세요.',
                    );
                  } else {
                    setIsNumberOnly('');
                  }

                  input.value = input.value.replace(/[^0-9]/g, '');
                }}
              />
              {errors.businessNumber && (
                <ErrorMessage text={errors.businessNumber.message} />
              )}
              {!errors.businessNumber && isNumberOnly && (
                <ErrorMessage text={isNumberOnly} />
              )}
            </InputField>
            <InputField>
              <Label>비밀번호</Label>
              <Input
                {...register('password', {
                  required: '비밀번호를 입력하세요.',
                })}
                type='password'
                placeholder='비밀번호 입력'
              />
              {errors.password && (
                <ErrorMessage text={errors.password.message} />
              )}
            </InputField>
            <Button>로그인</Button>
          </Form>
        </Container>
        <PasswordResetContainer>
          <PasswordReset onClick={() => navigate('/passport-reset')}>
            비밀번호 재설정
          </PasswordReset>
        </PasswordResetContainer>
      </LoginContainer>
    </>
  );
}
const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 42px 60px;
  border-radius: 4px;
  border: 2px solid ${(props) => props.theme.blueColors.scale1};
  background-color: ${(props) => props.theme.pointColors.white};
  width: 574px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 200px;
  height: 55px;
`;

const Title = styled.h2`
  margin-top: 8px;
  text-align: center;
  font-size: ${(props) => props.theme.fontSize.large};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.large};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

const Input = styled.input`
  padding: 13px 11px;
  border-radius: 4px;
  width: 100%;
  height: 48px;
  color: ${(props) => props.theme.grayColors.scale1};
  border: 2px solid ${(props) => props.theme.grayColors.scale3};
  :focus {
    border: 2px solid ${(props) => props.theme.priamryColors.primary};
  }
  ::placeholder {
    color: ${(props) => props.theme.grayColors.scale3};
  }
`;

const Button = styled.button`
  width: 100%;
  margin-top: 20px;
  padding: 12px 0;
  border-radius: 4px;
  color: ${(props) => props.theme.pointColors.white};
  background-color: ${(props) => props.theme.priamryColors.primary};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.small};
`;
const PasswordResetContainer = styled(FlexWrap)`
  align-items: flex-start;
  margin-top: 24px;
`;
const PasswordReset = styled.button`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 0;
  color: #757575;
  position: relative;
  :hover {
    ::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background-color: #757575;
    }
  }
`;

const Label = styled.label`
  font-size: 14px;
  color: #3a3b3e;
`;

const InputField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
`;

export default LoginPage;
