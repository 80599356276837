import { Dispatch, SetStateAction } from 'react';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { checkIsOwner } from '@/api/user';
import { CertificationCallback, ICheckIsOwnerPayload } from '@/types/login';
import { PassportChahgeProcessType } from '@/components/passwordChange/CheckChange';
import axiosInstance from '@/api/axiosSetup';

type CertificateHookProps = {
  businessNumber: number;
  setState: Dispatch<SetStateAction<PassportChahgeProcessType>>;
};

export const useCertificate = ({
  businessNumber,
  setState,
}: CertificateHookProps) => {
  const navigate = useNavigate();

  const mutation = useMutation<boolean, AxiosError, ICheckIsOwnerPayload>(
    ['isOwner'],
    (payload) => checkIsOwner(payload),
    {
      retry: false,
      onSuccess: () => {
        setState('NEW_PASSWORD_CHANGE');
      },
      onError: (err) => {
        console.log('err', err);
        alert(
          "'해당 사업자등록번호로 가입된\n대표자명과 휴대전화번호가 아닙니다.\n다시 본인인증 해주세요.'",
        );
      },
    },
  );

  const callback = async (response: CertificationCallback) => {
    const { success, imp_uid } = response;
    if (success && imp_uid) {
      try {
        const res = await axiosInstance.get(`/certifications/${imp_uid}`);
        if (res.status === 200) {
          const { name: sellerName, phoneNumber: storeTel } = res.data;
          mutation.mutate({
            businessNumber,
            name: sellerName,
            phoneNumber: storeTel,
          });
        }
      } catch {
        navigate(-1);
      }
    }
  };

  const onClickCertification = () => {
    /* 1. 가맹점 식별하기 */
    const { IMP } = window;
    IMP.init('imp35409296');
    /* 2. 본인인증 데이터 정의하기 */
    const data = {
      merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
      company: '프렌차이즈', // 회사명 또는 URL
      carrier: '', // 통신사
      name: '', // 이름
      phone: '', // 전화번호
    };
    /* 4. 본인인증 창 호출하기 */
    IMP.certification(data, callback);
  };

  return {
    onClickCertification,
  };
};
