import AUS from '@/assets/salesReport/nationality/AUS.png';
import BEL from '@/assets/salesReport/nationality/BEL.png';
import BGD from '@/assets/salesReport/nationality/BGD.png';
import BRA from '@/assets/salesReport/nationality/BRA.png';
import CAN from '@/assets/salesReport/nationality/CAN.png';
import CHE from '@/assets/salesReport/nationality/CHE.png';
import CHL from '@/assets/salesReport/nationality/CHL.png';
import CHN from '@/assets/salesReport/nationality/CHN.png';
import COL from '@/assets/salesReport/nationality/COL.png';
import CUB from '@/assets/salesReport/nationality/CUB.png';
import DEU from '@/assets/salesReport/nationality/DEU.png';
import ESP from '@/assets/salesReport/nationality/ESP.png';
import FJI from '@/assets/salesReport/nationality/FJI.png';
import FRA from '@/assets/salesReport/nationality/FRA.png';
import GBR from '@/assets/salesReport/nationality/GBR.png';
import HKG from '@/assets/salesReport/nationality/HKG.png';
import IDN from '@/assets/salesReport/nationality/IDN.png';
import IND from '@/assets/salesReport/nationality/IND.png';
import ITA from '@/assets/salesReport/nationality/ITA.png';
import JPN from '@/assets/salesReport/nationality/JPN.png';
import KAZ from '@/assets/salesReport/nationality/KAZ.png';
import KIR from '@/assets/salesReport/nationality/KIR.png';
import KOR from '@/assets/salesReport/nationality/KOR.png';
import LKA from '@/assets/salesReport/nationality/LKA.png';
import MEX from '@/assets/salesReport/nationality/MEX.png';
import MMR from '@/assets/salesReport/nationality/MMR.png';
import NLD from '@/assets/salesReport/nationality/NLD.png';
import NOR from '@/assets/salesReport/nationality/NOR.png';
import NZL from '@/assets/salesReport/nationality/NZL.png';
import PAK from '@/assets/salesReport/nationality/PAK.png';
import PER from '@/assets/salesReport/nationality/PER.png';
import PHL from '@/assets/salesReport/nationality/PHL.png';
import POL from '@/assets/salesReport/nationality/POL.png';
import RUS from '@/assets/salesReport/nationality/RUS.png';
import SAU from '@/assets/salesReport/nationality/SAU.png';
import SGP from '@/assets/salesReport/nationality/SGP.png';
import SWE from '@/assets/salesReport/nationality/SWE.png';
import THA from '@/assets/salesReport/nationality/THA.png';
import TUR from '@/assets/salesReport/nationality/TUR.png';
import TWN from '@/assets/salesReport/nationality/TWN.png';
import UKR from '@/assets/salesReport/nationality/UKR.png';
import USA from '@/assets/salesReport/nationality/USA.png';
import UZB from '@/assets/salesReport/nationality/UZB.png';
import VNM from '@/assets/salesReport/nationality/VNM.png';
import ZAF from '@/assets/salesReport/nationality/ZAF.png';

export const nationInfo: any = {
	AUS: { icon: AUS, label: '오스트레일리아' },
	BEL: { icon: BEL, label: '벨기에' },
	BGD: { icon: BGD, label: '방글라데시' },
	BRA: { icon: BRA, label: '브라질' },
	CAN: { icon: CAN, label: '캐나다' },
	CHE: { icon: CHE, label: '스위스' },
	CHL: { icon: CHL, label: '칠레' },
	CHN: { icon: CHN, label: '중국' },
	COL: { icon: COL, label: '콜롬비아' },
	CUB: { icon: CUB, label: '쿠바' },
	DEU: { icon: DEU, label: '독일' },
	ESP: { icon: ESP, label: '스페인' },
	FJI: { icon: FJI, label: '피지' },
	FRA: { icon: FRA, label: '프랑스' },
	GBR: { icon: GBR, label: '영국' },
	HKG: { icon: HKG, label: '홍콩' },
	IDN: { icon: IDN, label: '인도네시아' },
	IND: { icon: IND, label: '인도' },
	ITA: { icon: ITA, label: '이탈리아' },
	JPN: { icon: JPN, label: '일본' },
	KAZ: { icon: KAZ, label: '카자흐스탄' },
	KIR: { icon: KIR, label: '키리바시' },
	KOR: { icon: KOR, label: '한국' },
	LKA: { icon: LKA, label: '스리랑카' },
	MEX: { icon: MEX, label: '멕시코' },
	MMR: { icon: MMR, label: '미얀마' },
	NLD: { icon: NLD, label: '네덜란드' },
	NOR: { icon: NOR, label: '노르웨이' },
	NZL: { icon: NZL, label: '뉴질랜드' },
	PAK: { icon: PAK, label: '파키스탄' },
	PER: { icon: PER, label: '페루' },
	PHL: { icon: PHL, label: '필리핀' },
	POL: { icon: POL, label: '폴란드' },
	RUS: { icon: RUS, label: '러시아' },
	SAU: { icon: SAU, label: '사우디아라비아' },
	SGP: { icon: SGP, label: '싱가포르' },
	SWE: { icon: SWE, label: '스웨덴' },
	THA: { icon: THA, label: '태국' },
	TUR: { icon: TUR, label: '튀르키예' },
	TWN: { icon: TWN, label: '대만' },
	UKR: { icon: UKR, label: '우크라이나' },
	USA: { icon: USA, label: '미국' },
	UZB: { icon: UZB, label: '우즈베키스탄' },
	VNM: { icon: VNM, label: '베트남' },
	ZAF: { icon: ZAF, label: '남아프리카 공화국' },
	AND: { icon: null, label: '안도라' },
	ARE: { icon: null, label: '아랍에미리트' },
	AFG: { icon: null, label: '아프가니스탄' },
	ATG: { icon: null, label: '앤티가바부다' },
	AIA: { icon: null, label: '앵귈라' },
	ALB: { icon: null, label: '알바니아' },
	ARM: { icon: null, label: '아르메니아' },
	AGO: { icon: null, label: '앙골라' },
	ATA: { icon: null, label: '남극' },
	ARG: { icon: null, label: '아르헨티나' },
	ASM: { icon: null, label: '아메리칸사모아' },
	AUT: { icon: null, label: '오스트리아' },
	ABW: { icon: null, label: '아루바' },
	ALA: { icon: null, label: '올란드제도' },
	AZE: { icon: null, label: '아제르바이잔' },
	BIH: { icon: null, label: '보스니아헤르체고비나' },
	BRB: { icon: null, label: '바베이도스' },
	BFA: { icon: null, label: '부르키나파소' },
	BGR: { icon: null, label: '불가리아' },
	BHR: { icon: null, label: '바레인' },
	BDI: { icon: null, label: '부룬디' },
	BEN: { icon: null, label: '베냉' },
	BLM: { icon: null, label: '생바르텔레미' },
	BMU: { icon: null, label: '버뮤다' },
	BRN: { icon: null, label: '브루나이' },
	BOL: { icon: null, label: '볼리비아' },
	BES: { icon: null, label: '보네르섬' },
	BHS: { icon: null, label: '바하마' },
	BTN: { icon: null, label: '부탄' },
	BVT: { icon: null, label: '부베섬' },
	BWA: { icon: null, label: '보츠와나' },
	BLR: { icon: null, label: '벨라루스' },
	BLZ: { icon: null, label: '벨리즈' },
	CCK: { icon: null, label: '코코스제도' },
	COD: { icon: null, label: '콩고민주공화국' },
	CAF: { icon: null, label: '중앙아프리카공화국' },
	COG: { icon: null, label: '콩고공화국' },
	CIV: { icon: null, label: '코트디부아르' },
	COK: { icon: null, label: '쿡제도' },
	CMR: { icon: null, label: '카메룬' },
	CRI: { icon: null, label: '코스타리카' },
	CPV: { icon: null, label: '카보베르데' },
	CUW: { icon: null, label: '퀴라소' },
	CXR: { icon: null, label: '크리스마스섬' },
	CYP: { icon: null, label: '키프로스' },
	CZE: { icon: null, label: '체코' },
	DJI: { icon: null, label: '지부티' },
	DNK: { icon: null, label: '덴마크' },
	DMA: { icon: null, label: '도미니카 연방' },
	DOM: { icon: null, label: '도미니카 공화국' },
	DZA: { icon: null, label: '알제리' },
	ECU: { icon: null, label: '에콰도르' },
	EST: { icon: null, label: '에스토니아' },
	EGY: { icon: null, label: '이집트' },
	ESH: { icon: null, label: '서사하라' },
	ERI: { icon: null, label: '에리트레아' },
	ETH: { icon: null, label: '에티오피아' },
	FIN: { icon: null, label: '핀란드' },
	FLK: { icon: null, label: '포클랜드 제도' },
	FSM: { icon: null, label: '미크로네시아 연방' },
	FRO: { icon: null, label: '페로 제도' },
	GAB: { icon: null, label: '가봉' },
	GRD: { icon: null, label: '그레나다' },
	GEO: { icon: null, label: '조지아' },
	GUF: { icon: null, label: '프랑스령 기아나' },
	GGY: { icon: null, label: '건지섬' },
	GHA: { icon: null, label: '가나' },
	GIB: { icon: null, label: '지브롤터' },
	GRL: { icon: null, label: '그린란드' },
	GMB: { icon: null, label: '감비아' },
	GIN: { icon: null, label: '기니' },
	GLP: { icon: null, label: '과들루프' },
	GNQ: { icon: null, label: '적도 기니' },
	GRC: { icon: null, label: '그리스' },
	SGS: { icon: null, label: '사우스조지아 사우스샌드위치 제도' },
	GTM: { icon: null, label: '과테말라' },
	GUM: { icon: null, label: '괌' },
	GNB: { icon: null, label: '기니비사우' },
	GUY: { icon: null, label: '가이아나' },
	HAR: { icon: null, label: '하라르' },
	HMD: { icon: null, label: '허드 맥도널드 제도' },
	HND: { icon: null, label: '온두라스' },
	HRV: { icon: null, label: '크로아티아' },
	HTI: { icon: null, label: '아이티' },
	HUN: { icon: null, label: '헝가리' },
	IRL: { icon: null, label: '아일랜드' },
	ISR: { icon: null, label: '이스라엘' },
	IMN: { icon: null, label: '맨섬' },
	IOT: { icon: null, label: '영국령인도양지역' },
	IRQ: { icon: null, label: '이라크' },
	IRN: { icon: null, label: '이란' },
	ISL: { icon: null, label: '아이슬란드' },
	JEY: { icon: null, label: '저지섬' },
	JAM: { icon: null, label: '자메이카' },
	JOR: { icon: null, label: '요르단' },
	KEN: { icon: null, label: '케냐' },
	KGZ: { icon: null, label: '키르기스스탄' },
	KHM: { icon: null, label: '캄보디아' },
	COM: { icon: null, label: '코모로' },
	KNA: { icon: null, label: '세인트키츠네비스' },
	PRK: { icon: null, label: '조선민주주의인민공화국' },
	KWT: { icon: null, label: '쿠웨이트' },
	CYM: { icon: null, label: '케이맨제도' },
	LAO: { icon: null, label: '라오스' },
	LBN: { icon: null, label: '레바논' },
	LCA: { icon: null, label: '세인트루시아' },
	LIE: { icon: null, label: '리히텐슈타인' },
	LBR: { icon: null, label: '라이베리아' },
	LSO: { icon: null, label: '레소토' },
	LTU: { icon: null, label: '리투아니아' },
	LUX: { icon: null, label: '룩셈부르크' },
	LVA: { icon: null, label: '라트비아' },
	LBY: { icon: null, label: '리비아' },
	MAR: { icon: null, label: '모로코' },
	MCO: { icon: null, label: '모나코' },
	MDA: { icon: null, label: '몰도바' },
	MNE: { icon: null, label: '몬테네그로' },
	MAF: { icon: null, label: '생마르탱 공동체' },
	MDG: { icon: null, label: '마다가스카르' },
	MHL: { icon: null, label: '마셜 제도' },
	MKD: { icon: null, label: '북마케도니아' },
	MLI: { icon: null, label: '말리' },
	MNG: { icon: null, label: '몽골' },
	MAC: { icon: null, label: '마카오' },
	MNP: { icon: null, label: '북마리아나제도' },
	MTQ: { icon: null, label: '마르티니크' },
	MRT: { icon: null, label: '모리타니' },
	MSR: { icon: null, label: '몬트세랫' },
	MLT: { icon: null, label: '몰타' },
	MUS: { icon: null, label: '모리셔스' },
	MDV: { icon: null, label: '몰디브' },
	MWI: { icon: null, label: '말라위' },
	MYS: { icon: null, label: '말레이시아' },
	MOZ: { icon: null, label: '모잠비크' },
	NAM: { icon: null, label: '나미비아' },
	NCL: { icon: null, label: '누벨칼레도니' },
	NER: { icon: null, label: '니제르' },
	NFK: { icon: null, label: '노퍽섬' },
	NGA: { icon: null, label: '나이지리아' },
	NIC: { icon: null, label: '니카라과' },
	NPL: { icon: null, label: '네팔' },
	NRU: { icon: null, label: '나우루' },
	NIU: { icon: null, label: '니우에' },
	OMN: { icon: null, label: '오만' },
	PAN: { icon: null, label: '파나마' },
	PYF: { icon: null, label: '프랑스령폴리네시아' },
	PNG: { icon: null, label: '파푸아뉴기니' },
	SPM: { icon: null, label: '생피에르미클롱' },
	PCN: { icon: null, label: '핏케언제도' },
	PRI: { icon: null, label: '푸에르토리코' },
	PSE: { icon: null, label: '팔레스타인' },
	PRT: { icon: null, label: '포르투갈' },
	PLW: { icon: null, label: '팔라우' },
	PRY: { icon: null, label: '파라과이' },
	QAT: { icon: null, label: '카타르' },
	REU: { icon: null, label: '레위니옹' },
	ROU: { icon: null, label: '루마니아' },
	SRB: { icon: null, label: '세르비아' },
	RWA: { icon: null, label: '르완다' },
	SLB: { icon: null, label: '솔로몬제도' },
	SYC: { icon: null, label: '세이셸' },
	SDN: { icon: null, label: '수단' },
	SHN: { icon: null, label: '세인트헬레나' },
	SVN: { icon: null, label: '슬로베니아' },
	SJM: { icon: null, label: '스발바르얀마옌제도' },
	SVK: { icon: null, label: '슬로바키아' },
	SLE: { icon: null, label: '시에라리온' },
	SMR: { icon: null, label: '산마리노' },
	SEN: { icon: null, label: '세네갈' },
	SOM: { icon: null, label: '소말리아' },
	SUR: { icon: null, label: '수리남' },
	SSD: { icon: null, label: '남수단' },
	STP: { icon: null, label: '상투메프린시페' },
	SLV: { icon: null, label: '엘살바도르' },
	SXM: { icon: null, label: '신트마르턴' },
	SYR: { icon: null, label: '시리아' },
	SWZ: { icon: null, label: '에스와티니' },
	TCA: { icon: null, label: '터크스케이커스제도' },
	TCD: { icon: null, label: '차드' },
	ATF: { icon: null, label: '프랑스령남방및남극지역' },
	TGO: { icon: null, label: '토고' },
	TJK: { icon: null, label: '타지키스탄' },
	TKL: { icon: null, label: '토켈라우' },
	TLS: { icon: null, label: '동티모르' },
	TKM: { icon: null, label: '투르크메니스탄' },
	TUN: { icon: null, label: '튀니지' },
	TON: { icon: null, label: '통가' },
	TTO: { icon: null, label: '트리니다드토바고' },
	TUV: { icon: null, label: '투발루' },
	TZA: { icon: null, label: '탄자니아' },
	UGA: { icon: null, label: '우간다' },
	UMI: { icon: null, label: '미국령군소제도' },
	URY: { icon: null, label: '우루과이' },
	VAT: { icon: null, label: '바티칸시국' },
	VCT: { icon: null, label: '세인트빈센트그레나딘' },
	VEN: { icon: null, label: '베네수엘라' },
	VGB: { icon: null, label: '영국령버진아일랜드' },
	VIR: { icon: null, label: '미국령버진아일랜드' },
	VUT: { icon: null, label: '바누아투' },
	WLF: { icon: null, label: '왈리스푸투나' },
	WSM: { icon: null, label: '사모아' },
	YEM: { icon: null, label: '예멘' },
	MYT: { icon: null, label: '마요트' },
	ZMB: { icon: null, label: '잠비아' },
	ZWE: { icon: null, label: '짐바브웨' },
};

type PickerData = Array<{
	value: number;
	label: string;
}>;
export const monthData: PickerData = [
	{ value: 0, label: '1월' },
	{ value: 1, label: '2월' },
	{ value: 2, label: '3월' },
	{ value: 3, label: '4월' },
	{ value: 4, label: '5월' },
	{ value: 5, label: '6월' },
	{ value: 6, label: '7월' },
	{ value: 7, label: '8월' },
	{ value: 8, label: '9월' },
	{ value: 9, label: '10월' },
	{ value: 10, label: '11월' },
	{ value: 11, label: '12월' },
];

const getLatestYear = (count: number) => {
	let result = [];
	const thisYear = new Date().getFullYear();
	for (let i = 0; i < count; i++) {
		result.push({
			value: thisYear - i,
			label: `${thisYear - i}년`,
		});
	}
	return result;
};
export const yearData = getLatestYear(10);
