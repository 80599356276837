import QuestionIcon from '@/assets/common/question.png';
import DateSelector from '@/components/common/DateSelector';
import FlexWrap from '@/components/common/FlexWrap';
import LoadingView from '@/components/common/LoadingView';
import Paging from '@/components/common/Paging';
import TableHeader from '@/components/common/TableHeader';
import ViewSelector from '@/components/common/ViewSelector';
import PaymentGraph from '@/components/home/PaymentGraph';
import RefundCard from '@/components/home/RefundCard';
import RefundDetailGraph from '@/components/home/RefundDetailGraph';
import RefundFilterTable from '@/components/home/RefundFilterTable';
import RefundSummary from '@/components/home/RefundSummary';
import { RefundDetailGraphHeaderContent } from '@/constants/refundDetail';
import { useAppDispatch } from '@/hooks/useReduxHooks';
import { useRefndDetail } from '@/hooks/useRefundDetail';
import { resetRefundFilter, setFilterSeletor } from '@/store/modules/refund';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

function Home() {
  const {
    data,
    isLoading,
    totalPage,
    totalElements,
    pageNumber,
    setPageNumber,
    onDownloadExcel,
  } = useRefndDetail();
  const dispatch = useAppDispatch();
  const [isActiveTooltip, setIsActiveTooltip] = useState(false);
  const [detailDatas, setDetailDatas] = useState(data?.detail);
  const [summaryDatas, setSummaryDatas] = useState(data?.summary);

  useEffect(() => {
    setDetailDatas(data?.detail);
    setSummaryDatas(data?.summary);
  }, [isLoading, data]);

  useEffect(() => {
    return () => {
      dispatch(resetRefundFilter());
      dispatch(
        setFilterSeletor({ startDate: new Date(), endDate: new Date() }),
      );
    };
  }, []);

  if (isLoading && !summaryDatas)
    return (
      <LoadingContainer>
        <LoadingView />
      </LoadingContainer>
    );

  return (
    <Wrapper>
      <Container>
        <Title>환급 현황</Title>
        <Inner>
          <Header>
            <SubTitle>
              누적 환급현황
              <QuestionWrapper onMouseLeave={() => setIsActiveTooltip(false)}>
                <QuestionImg
                  onMouseOver={() => setIsActiveTooltip(true)}
                  src={QuestionIcon}
                />
              </QuestionWrapper>
              {isActiveTooltip && (
                <Tooltip>
                  <Arrow />
                  <TooltipContent>
                    {`누적 환급현황은 '승인 확정 건' 들에 대해서만 표기합니다. 이에 따라 상세 내역 합계금액과 다소 상이할 수 있습니다.`}
                  </TooltipContent>
                </Tooltip>
              )}
            </SubTitle>
            <FlexWrap alignItems='center' gap='20px'>
              <ViewSelector />
              <DateSelector />
              {summaryDatas && (
                <Button onClick={onDownloadExcel}>파일 다운로드</Button>
              )}
            </FlexWrap>
          </Header>
          <RefundContainer>
            {summaryDatas && <RefundCard refundInfo={summaryDatas} />}
          </RefundContainer>
          <GraphTitle>일별 결제 금액</GraphTitle>
          {summaryDatas && (
            <PaymentGraph data={summaryDatas.summaryGraphList} />
          )}
          <FlexWrap justifyContent='space-between'>
            <GraphTitle>환급내역</GraphTitle>
            <GraphSubTitle>
              * 실적명세서에는 환급 승인된 건만 처리됩니다.
            </GraphSubTitle>
          </FlexWrap>
          {detailDatas && (
            <FlexWrap>
              <RefundFilterTable
                data={detailDatas.total}
                setPageNumber={setPageNumber}
              />
            </FlexWrap>
          )}
          {detailDatas?.total && <RefundSummary data={detailDatas.total} />}
          <TableHeader titleList={RefundDetailGraphHeaderContent} />
          {detailDatas?.detail ? (
            <>
              <RefundDetailGraph
                data={detailDatas.detail.content}
                pageNumber={pageNumber}
                totalElements={totalElements}
              />
            </>
          ) : (
            <div style={{ marginTop: 20 }}>환급건이 없습니다.</div>
          )}
        </Inner>

        {detailDatas && (
          <PagingContainer>
            <Paging
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              totalPage={totalPage}
            />
          </PagingContainer>
        )}
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1200px;
  max-height: 100vh;
  flex: 1;
  overflow: scroll;

  background-color: ${(props) => props.theme.blueColors.scale3};
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const RefundContainer = styled(FlexWrap)`
  margin-bottom: 50px;
  gap: 20px;
`;
const Inner = styled.div`
  padding: 38px 40px;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  margin-bottom: 24px;
`;
const Title = styled.p`
  font-size: ${(props) => props.theme.fontSize.xLarge};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.xLarge};
  padding: 21px 40px;
  border-bottom: 1px solid #bdbdbd;
  color: #0b42ad;
`;
const SubTitle = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
`;
const GraphTitle = styled.p`
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 24px;
`;
const GraphSubTitle = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
`;
const PagingContainer = styled.div`
  margin: 20px 0;
`;
const Button = styled.button`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  padding: 3px 20px;
  color: #0b42ad;
  background-color: #ffffff;
  border: 2px solid #0b42ad;
  border-radius: 4px;
`;
const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 1200px;
`;

const QuestionWrapper = styled(FlexWrap)<{ onMouseLeave: any }>`
  width: 25px;
  height: 36px;
  align-items: center;
`;
const QuestionImg = styled.img`
  width: 22px;
  height: 22px;
  cursor: pointer;
  margin-left: 4px;
  margin-bottom: 2px;
`;
const Tooltip = styled.div`
  position: absolute;
  top: 120%;
  left: 46px;
  background-color: #212121;
  width: 660px;
  z-index: 10;
  border-radius: 4px;
  padding: 8px 12px;
`;
const TooltipContent = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #ffffff;
  white-space: pre-wrap;
  text-align: left;
`;
const Arrow = styled.div`
  position: absolute;
  top: -19px;
  left: 70px;
  width: 10px;
  height: 11px;
  border-top: 8px solid transparent;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 12px solid #212121;
`;
export default Home;
